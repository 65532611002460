<template>
  <v-container
    id="lives"
    fluid
    tag="section"
    class="container-full-size"
  >
    <v-data-table
      :headers="headers"
      :items="data"
      sort-by="date"
      :sort-desc="true"
      :search="search"
      :no-data-text="str['no_data']"
      :no-results-text="str['no_data']"
      class="elevation-1"
      @click:row="openDialog"
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="str['search']"
            single-line
            hide-details
          />
          <v-spacer />
          <v-btn
            v-if="editPermission"
            color="success"
            dark
            class="mb-2"
            @click="openDialog()"
          >
            {{ str['add'] }}
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.day_of_week="{ item }">
        {{ str[item.day_of_week] }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          v-if="editPermission"
          small
          class="mr-0"
          color="error"
          @click.stop="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
    <v-dialog
      v-model="dialogFields"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <div>
            <span class="headline">
              {{ str['details'] }}
            </span>
          </div>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              v-for="item in fields"
              :key="item.id"
              :cols="item.cols ? item.cols : 12"
            >
              <v-text-field
                v-if="item.type === 'input'"
                v-model="item.value"
                :label="item.title"
                hide-details
              />
              <v-autocomplete
                v-if="item.type === 'select'"
                v-model="item.value"
                :label="item.title"
                item-text="label"
                item-value="value"
                :items="item.items"
                :no-data-text="str['no_data']"
                hide-details
              />
              <v-menu
                v-if="item.type === 'timepicker'"
                v-model="item.menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="item.value"
                    v-bind:label="item.title"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    hide-details
                  />
                </template>
                <v-time-picker
                  v-model="item.value"
                  style="margin: 0;"
                  format="24hr"
                  @change="item.menu = false"
                />
              </v-menu>
              <div v-if="item.type === 'image'">
                <div>
                  {{ str['image'] }}
                </div>
                <div
                  v-if="item.value"
                  class="form-field-image"
                >
                  <input
                    id="input-image"
                    type="file"
                    @change="addNewImage"
                    accept=".png,.jpg,.jpeg"
                  >
                  <v-img
                    contain
                    :src="item.value"
                    alt="Conteúdo"
                    width="300px"
                    height="300px"
                  />
                </div>
                <div
                  v-if="!item.value"
                  class="form-field-image"
                >
                  <input
                    id="input-image"
                    type="file"
                    @change="addNewImage"
                    accept=".png,.jpg,.jpeg"
                  >
                  <v-btn
                    class="mx-2"
                    fab
                    dark
                    small
                    color="success"
                  >
                    <v-icon dark>
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="default"
            @click="closeDialog()"
          >
            {{ str['cancel'] }}
          </v-btn>
          <v-btn
            color="success"
            @click="save()"
          >
            {{ str['save'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import Api from '@/services/Api'
  import Utils from '@/services/Utils'
  import moment from 'moment'

  export default {
    data () {
      const user = Utils.getUser()
      const headers = [
        { text: window.strings['day_of_week'], value: 'day_of_week', align: 'left', width: 140 },
        { text: window.strings['title'], value: 'title', align: 'center', width: 100 },
        { text: window.strings['start_time'], value: 'start_time', align: 'center', width: 120 },
        { text: window.strings['end_time'], value: 'end_time', align: 'center', width: 130 },
        { text: window.strings['host'], value: 'host', align: 'center', width: 130 },
        { text: window.strings['actions'], value: 'actions', sortable: false, align: 'center', width: 70 },
      ]
      const editPermission = Utils.hasPermission('lives_edit') ? true : false
      return {
        user: user,
        str: window.strings,
        editPermission: editPermission,
        headers: headers,
        data: [],
        search: '',
        dialogFields: false,
        fields: [{
          type: 'input',
          id: 'title',
          title: window.strings['title'],
          value: '',
          cols: 12,
        }, {
          type: 'select',
          id: 'day_of_week',
          title: window.strings['day_of_week'],
          items: Utils.getLiveDaysOfWeek(),
          value: '',
          cols: 12,
          menu: false,
        }, {
          type: 'timepicker',
          id: 'start_time',
          title: window.strings['start_time'],
          value: '',
          cols: 6,
          menu: false,
        }, {
          type: 'timepicker',
          id: 'end_time',
          title: window.strings['end_time'],
          value: '',
          cols: 6,
          menu: false,
        }, {
          type: 'input',
          id: 'host',
          title: window.strings['host'],
          value: '',
          cols: 12,
        }, {
          type: 'input',
          id: 'live_url',
          title: window.strings['link_url'],
          value: '',
          cols: 12,
        }, {
          type: 'image',
          id: 'image',
          title: window.strings['image'],
          value: '',
          cols: 12,
        }],
      }
    },
    watch: {
      dialogFields (val) {
        const dialog = document.getElementsByClassName('v-dialog')
        if (dialog && dialog[0]) {
          dialog[0].scrollTo(0, 0)
        }
        val || this.closeDialog()
      },
    },
    beforeMount: function () {
      if (!this.user) {
        return false
      }
      this.getItems()
    },
    methods: {
      getItems: function () {
        const self = this
        this.$isLoading(true)
        Api.getLives(function (response) {
          self.$isLoading(false)
          if (response.success) {
            response.data.forEach((item) => {
              item.start_time = moment.utc(item.start_time, 'HH:mm').local().format('HH:mm')
              item.end_time = moment.utc(item.end_time, 'HH:mm').local().format('HH:mm')
            })
            self.data = response.data
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      openDialog: function (item) {
        this.itemToEdit = item
        this.setData()
        this.dialogFields = true
      },
      closeDialog: function () {
        this.dialogFields = false
      },
      addNewImage (event) {
        const self = this
        const file = event.srcElement.files[0]
        const reader = new FileReader()

        reader.readAsDataURL(file)
        reader.onload = function (e) {
          const image = new Image()
          image.src = reader.result

          image.onload = function () {
            let out = null
            if (image.width < self.imageMaxSize && image.height < self.imageMaxSize) {
              out = e.target.result
            } else {
              out = resizeImage(image)
            }
            self.saveNewImage(out)
          }
        }
        reader.onerror = function () { }

        function resizeImage(image) {
          const canvas = document.createElement('canvas')
          let width = image.width
          let height = image.height

          if (width > height) {
            if (width > self.imageMaxSize) {
              height *= self.imageMaxSize / width
              width = self.imageMaxSize
            }
          } else {
            if (height > self.imageMaxSize) {
              width *= self.imageMaxSize / height
              height = self.imageMaxSize
            }
          }
          canvas.width = width
          canvas.height = height
          const ctx = canvas.getContext('2d')
          ctx.drawImage(image, 0, 0, width, height)
          return canvas.toDataURL()
        }
      },
      saveNewImage(image) {
        for (let i = 0; i < this.fields.length; i++) {
          if (this.fields[i].id === 'image') {
            this.fields[i].value = image
            this.$set(this.fields, i, this.fields[i])
            break
          }
        }
        document.getElementById('input-image').value = ''
      },
      setData () {
        const self = this
        this.fields.forEach((item) => {
          item.value = this.itemToEdit ? this.itemToEdit[item.id] : ''
        })
        if (this.itemToEdit && this.itemToEdit.image) {
          Api.getImage(this.itemToEdit.image, function (response) {
            if (response.success && response.data && response.data[0]) {
              for (let i = 0; i < self.fields.length; i++) {
                if (self.fields[i].id === 'image') {
                  self.fields[i].value = response.data[0].file
                  break
                }
              }
            }
          })
        }
      },
      getData (callback) {
        const self = this
        const data = {}
        for (let i = 0; i < this.fields.length; i++) {
          if (!this.fields[i].value) {
            this.$alert(
              window.strings['fields_to_be_filled'],
              '',
              'warning',
              Utils.getAlertOptions()
            )
            return callback()
          }
          if (this.fields[i].type === 'timepicker') {
            data[this.fields[i].id] = moment(this.fields[i].value, 'HH:mm').utc().format('HH:mm')
          } else {
            data[this.fields[i].id] = this.fields[i].value
          }
        }
        data.timezone = moment().utcOffset() / 60
        if (this.itemToEdit) {
          data.id = this.itemToEdit.id
        }
        if (data.image) {
          if (this.itemToEdit && this.itemToEdit.image) {
            Api.updateImage({
              id: this.itemToEdit.image,
              name: 'image_live_' + (new Date()).getTime(),
              type: 1,
              file: data.image
            }, function (response) {
              if (response.success) {
                data.image = response.data.id.toString()
                callback(data)
              } else {
                self.$alert(
                  response.message,
                  '',
                  'warning',
                  Utils.getAlertOptions()
                )
              }
            })
          } else {
            Api.newImage({
              name: 'image_live_' + (new Date()).getTime(),
              type: 1,
              file: data.image
            }, function (response) {
              if (response.success) {
                data.image = response.data.id.toString()
                callback(data)
              } else {
                self.$alert(
                  response.message,
                  '',
                  'warning',
                  Utils.getAlertOptions()
                )
              }
            })
          }
        } else {
          callback(data)
        }
      },
      save() {
        const self = this
        this.$isLoading(true)
        this.getData(function (data) {
          if (!data) {
            return self.$isLoading(false)
          }
          if (data.id) {
            Api.updateLive(data, function (response) {
              self.$isLoading(false)
              if (response.success) {
                self.closeDialog()
                self.getItems()
              } else {
                self.$alert(
                  response.message,
                  '',
                  'warning',
                  Utils.getAlertOptions()
                )
              }
            })
          } else {
            Api.newLive(data, function (response) {
              self.$isLoading(false)
              if (response.success) {
                self.closeDialog()
                self.getItems()
              } else {
                self.$alert(
                  response.message,
                  '',
                  'warning',
                  Utils.getAlertOptions()
                )
              }
            })
          }
        })
      },
      deleteItem (item) {
        const self = this
        this.$confirm(
          window.strings['are_you_sure_delete'],
          '',
          'warning',
          Utils.getAlertOptions(true, true)
        ).then(() => {
          self.$isLoading(true)
          Api.deleteLive({
          id: item.id,
        }, function (response) {
            self.$isLoading(false)
            if (response.success) {
              self.getItems()
            } else {
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        }).catch(() => { })
      },
    },
  }
</script>
